import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import classNames from 'classnames';
import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';

import { FiSend } from 'react-icons/fi';

import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '88vh',
  },
  marginTop: {
    marginTop: 25,
  },
  textField: {
    width: '50ch',
    [theme.breakpoints.down('xs')]: {
      width: '30ch',
    },
  },
  submitButtonBox: {
    textAlign: 'rigth',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  submitButton: {
    borderColor: theme.palette.primary.main,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const isValidEmail = (str) => emailRegEx.test(String(str));

export default function ContactPage() {
  const data = useStaticQuery(graphql`
    query AuthorEmailQuery {
      site {
        siteMetadata {
          authorEmail
          contactFormUrl
        }
      }
    }
  `);

  const { authorEmail, contactFormUrl } = data.site.siteMetadata;

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const [errorContactName, setErrorContactName] = useState(false);
  const [errorContactEmail, setErrorContactEmail] = useState(false);
  const [errorContactMessage, setErrorContactMessage] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const resetForm = () => {
    setContactName('');
    setContactEmail('');
    setContactMessage('');
  };

  const validation = () => {
    let result = true;
    if (/[\w\d]{2,}/.test(contactName)) {
      setErrorContactName(false);
    } else {
      setErrorContactName(true);
      result = false;
    }
    if (isValidEmail(contactEmail)) {
      setErrorContactEmail(false);
    } else {
      setErrorContactEmail(true);
      result = false;
    }
    if (contactMessage.length > 9 && /\w{3,}/.test(contactMessage)) {
      setErrorContactMessage(false);
    } else {
      setErrorContactMessage(true);
      result = false;
    }
    return result;
  };

  const handleSubmitForm = () => {
    if (!validation()) return;

    const formData = new global.FormData();
    formData.append('name', contactName);
    formData.append('email', contactEmail);
    formData.append('message', contactMessage);

    axios({
      url: contactFormUrl,
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: formData,
    })
      .then((response) => {
        if (response.data.indexOf('success') >= 0) {
          setDialogTitle('Success :)');
          setDialogMessage('Your message has been sent!\nPlease give me a while to respond.');
          resetForm();
        } else {
          setDialogTitle('Error :(');
          setDialogMessage(`Sorry, but an error occured. Please try again later!\n(or send an email to: ${authorEmail})`);
        }
        handleDialogOpen();
      })
      .catch((error) => {
        setDialogTitle('Error :(');
        setDialogMessage(`Some terrible connection error occured:\n${error.toString().replace(/^Error: /, '')}\nPlease send an email to: ${authorEmail}`);
        handleDialogOpen();
      });
  };

  const handleContactNameChange = (event) => {
    setContactName(event.target.value);
  };

  const handleContactEmailChange = (event) => {
    setContactEmail(event.target.value);
  };

  const handleContactMessageChange = (event) => {
    setContactMessage(event.target.value);
  };

  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Contact Page" />
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justify="space-around"
        alignContent="center"
        alignItems="center"
        spacing={8}
        className={classes.wrapper}
      >
        <Grid item key="1" md={12}>
          <Box className={classes.marginTop}>
            <Typography variant="h1" component="h1" align="center" gutterBottom>
              Contact Form
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
              Want to contact me? Fill up the form!
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            // autoComplete="off"
            className={classes.marginTop}
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmitForm(contactName, contactEmail, contactMessage);
            }}
          >
            <TextField
              required
              label="Name"
              variant="outlined"
              margin="normal"
              error={errorContactName}
              helperText={errorContactName && 'Provide a real name'}
              value={contactName}
              onChange={handleContactNameChange}
              className={classes.textField}
            />
            <br />
            <TextField
              required
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              error={errorContactEmail}
              helperText={errorContactEmail && 'Invalid email address'}
              value={contactEmail}
              onChange={handleContactEmailChange}
              className={classes.textField}
            />
            <br />
            <TextField
              required
              label="Enter message here"
              variant="outlined"
              margin="normal"
              multiline
              rows={10}
              error={errorContactMessage}
              helperText={errorContactMessage && 'Enter a longer message (aleast 10 chars)'}
              value={contactMessage}
              onChange={handleContactMessageChange}
              className={classes.textField}
            />
            <Box
              textAlign="right"
              className={classNames(classes.marginTop, classes.submitButtonBox)}
            >
              <Button
                type="submit"
                variant="outlined"
                startIcon={<FiSend />}
                // loading
                className={classes.submitButton}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent id="alert-dialog-description">
          {dialogMessage.split('\n').map((text) => (
            <DialogContentText align="center">
              {text}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
